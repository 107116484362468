import axios from 'axios'

export default {
    updateAuthorization: function (user) {
        axios.defaults.authorization = 'Basic ' + window.btoa(user.email + ':' + user.password)
    },
    processResponse: function (response, callback) {
        try {
            const responseData = JSON.parse(response.request.response)
            let data = null
            if (responseData) {
                data = responseData.data
            }
            callback({
                success: responseData.success,
                data: data,
                message: responseData.message,
            })
        } catch (error) {
            callback({
                success: false,
                message: 'Servidor a inicializar, aguarda uns segundos por favor.',
            })
        }
    },
    getAlertOptions: function (confirmation, warning) {
        return {
            confirmButtonText: confirmation ? window.strings['yes'] : window.strings['ok'],
            cancelButtonText: confirmation ? window.strings['no'] : window.strings['cancel'],
            confirmButtonColor: warning ? '#fb8c00' : '#509dc8'
        }
    },
    extractEmail: function (invalidEmail) {
        // Expressão regular para encontrar o padrão de e-mail
        var regex = /([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})/;
        
        // Aplica a expressão regular ao e-mail completo
        var match = regex.exec(invalidEmail);
        
        // Verifica se houve correspondência e retorna o e-mail encontrado
        if (match) {
            return match[0];
        } else {
            return null; // Retorna null se não encontrar um e-mail
        }
    },
    getReportIssueTypes: function () {
        return [{
            value: 0,
            status: 'none',
            label: window.strings['n_a']
        }, {
            value: 1,
            status: 'bug',
            label: window.strings['bug'],
            type: 'error'
        }, {
            value: 2,
            status: 'user_error',
            label: window.strings['user_error'],
            type: 'yellow'
        }, {
            value: 3,
            status: 'feature_request',
            label: window.strings['feature_request'],
            type: 'success'
        }, {
            value: 4,
            status: 'administrative_issue',
            label: window.strings['administrative_issue'],
            type: 'warning'
        }]
    },
    getReportIssueStatus: function () {
        return [{
            value: 0,
            status: 'open',
            label: window.strings['opened'],
            type: 'error'
        }, {
            value: 1,
            status: 'in_progress',
            label: window.strings['progressing'],
            type: 'warning'
        }, {
            value: 2,
            status: 'resolved',
            label: window.strings['resolved'],
            type: 'yellow'
        }, {
            value: 3,
            status: 'closed',
            label: window.strings['closed'],
            type: 'success'
        }]
    },
}